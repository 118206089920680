import React, { createContext, useContext, useState, useEffect } from 'react';

// Hooks

export const AppContext = createContext({
  cusorState: 'default',
  cursorHover: false,
});

export const PageWrapper = ({ children }) => {
  const [cusorState, setCursorState] = useState('default');
  const [cursorHover, setCursorHover] = useState(false);

  return (
    <AppContext.Provider
      value={{
        cusorState,
        setCursorState,
        cursorHover,
        setCursorHover,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
