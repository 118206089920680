export const usePadBottom = (size) => {
  switch (size) {
    case 'small':
      return 'pb-16 lg:pb-40';
    case 'medium':
      return 'pb-16 lg:pb-64';
    case 'large':
      return 'pb-16 lg:pb-96';
  }
};
