import React from 'react';
import { motion } from 'framer-motion';

import { NavLink } from './navLink';

import { useAppContext } from '../../state';

import { svg } from '../nav/footer.module.css';

const navVars = {
  open: {
    opacity: 1,
    transition: {
      duration: 0.8,
      delay: 0.8,
    },
  },
  closed: {
    opacity: 0,
    transition: {
      duration: 0.8,
    },
  },
};

export const Nav = ({ items, handles, onClose }) => {
  const { setCursorHover } = useAppContext();
  return (
    <motion.div
      className="h-screen w-screen px-gutter flex flex-col justify-end items-start z-50 relative text-nav-text"
      variants={navVars}
    >
      <div className="grid gap-4 lg:grid-flow-col lg:grid-cols-2 lg:grid-rows-3 mb-10 xl:gap-10">
        {items.map((item, index) => (
          <NavLink
            {...item}
            className="text-4xl md:text-5xl xl:text-6xl font-black"
            onClick={() => {
              onClose();
              setCursorHover(false);
            }}
            onMouseEnter={() => setCursorHover(true)}
            onMouseLeave={() => setCursorHover(false)}
          />
        ))}
      </div>
      <div className="border-t border-current flex justify-end w-full py-8">
        {handles.map(({ icon, link }) => (
          <a
            href={link}
            className="w-10 ml-4"
            onMouseEnter={() => setCursorHover(true)}
            onMouseLeave={() => setCursorHover(false)}
          >
            <div dangerouslySetInnerHTML={{ __html: icon }} className={svg} />
          </a>
        ))}
      </div>
    </motion.div>
  );
};
