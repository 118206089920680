export const useSanityPath = (props) => {
  const { _type, main, slug } = props || {};

  function getPath(_type, slug, main) {
    const productSlug = (main && main.slug) || {};
    switch (_type) {
      case 'page':
        return `/${slug.current}`;
      case 'caseStudy':
        return `/case-studies/${productSlug.current}`;
      case 'homePage':
        return `/`;
      default:
        return null;
    }
  }

  const path = getPath(_type, slug, main);

  return path;
};
