import React, { useRef, useLayoutEffect, useCallback } from 'react';

import useWindowSize from '../../hooks/useWindowSize';

const SkewScroller = ({ children }) => {
  //Hook to grab window size
  const size = useWindowSize();

  // Ref for parent div and scrolling div
  const scrollContainer = useRef();

  // Configs
  const data = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0,
  };

  // update scrollable height when browser is resizing
  const resizePageHeight = useCallback((entries) => {
    for (let entry of entries) {
      document.body.style.height = `${entry.contentRect.height}px`;
    }
  }, []);

  // observe when browser is resizing
  useLayoutEffect(() => {
    requestAnimationFrame(() => skewScrolling());
    if (typeof window !== `undefined`) {
      const resizeObserver = new ResizeObserver((entries) => resizePageHeight(entries));
      scrollContainer && resizeObserver.observe(scrollContainer.current);
      return () => resizeObserver.disconnect();
    }
  }, [scrollContainer, resizePageHeight]);

  // Scrolling
  const skewScrolling = () => {
    //Set Current to the scroll position amount
    data.current = window.scrollY;
    // Set Previous to the scroll previous position
    data.previous += (data.current - data.previous) * data.ease;
    // Set rounded to
    data.rounded = Math.round(data.previous * 100) / 100;

    // Difference between
    const difference = data.current - data.rounded;
    const acceleration = difference / size.width;
    const velocity = +acceleration;
    const skew = velocity * 7.5;

    //Assign skew and smooth scrolling to the scroll container
    scrollContainer.current.style.transform = `translate3d(0, -${data.rounded}px, 0) skewY(${skew}deg)`;

    //loop vai raf
    requestAnimationFrame(() => skewScrolling());
  };

  return (
    <div ref={scrollContainer} className="scroll">
      {children}
    </div>
  );
};

export default SkewScroller;
