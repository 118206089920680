import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';

// Hooks
import { useSiteConfig } from '../../GraphQl/useSiteConfig';
import useResizeObserver from 'use-resize-observer';
import useScrollLock from 'use-scroll-lock';
import useHeadroom from 'react-useheadroom';

import { useAppContext } from '../../state/index';

// SVG
import Logo from '../../svg/logo.svg';

// Components
import { MenuToggle } from './menuToggle';
import { Nav } from './nav';

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${
      height * 4 + 200
    }px at calc(100vw - var(--headerGutter) - 1.5rem) var(--circleOffset))`,
    background: 'var(--navBg',
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
      background: { type: 'none' },
    },
  }),
  closed: {
    clipPath: 'circle(20px at calc(100vw - var(--headerGutter) - 1.5rem) var(--circleOffset))',
    background: 'transparent',
    transition: {
      delay: 0.8,
      type: 'spring',
      stiffness: 400,
      damping: 40,
      background: { duration: 0, delay: 2 },
    },
  },
};

export const Header = () => {
  const [navOpen, setNavOpen] = useState(false);

  const { mainNav, socialHandles } = useSiteConfig();

  const closeNav = () => setNavOpen(false);
  const toggleNav = () => setNavOpen((prev) => !prev);

  const { ref, width = 1 } = useResizeObserver();
  const { setCursorState, setCursorHover } = useAppContext();

  const isPinned = useHeadroom({});

  useScrollLock(navOpen);

  useEffect(() => {
    if (navOpen) {
      setCursorState('reversed');
    }
    return () => {
      setCursorState('default');
    };
  }, [navOpen]);

  return (
    <>
      <Link
        to="/"
        className="w-20 text-nav-bg fixed top-9 left-headerGutter z-75 lg:w-24 transition-all duration-300"
        onMouseEnter={() => setCursorHover(true)}
        onMouseLeave={() => setCursorHover(false)}
        style={{
          transform: isPinned ? `translate3d(0,0px,0)` : `translate3d(0,-100px,0)`,
        }}
      >
        <Logo />
      </Link>
      <MenuToggle
        onClick={toggleNav}
        animate={navOpen ? 'open' : 'closed'}
        onMouseEnter={() => setCursorHover(true)}
        onMouseLeave={() => setCursorHover(false)}
        style={{
          transform: isPinned ? `translate3d(0,0px,0)` : `translate3d(0,-100px,0)`,
        }}
      />
      <motion.nav
        initial={'closed'}
        animate={navOpen ? 'open' : 'closed'}
        className="fixed top-0 right-0 h-screen w-screen z-2000 transition-all duration-300"
        ref={ref}
        style={{
          pointerEvents: navOpen ? 'all' : 'none',
          transform: isPinned ? `translate3d(0,0px,0)` : `translate3d(0,-100px,0)`,
        }}
      >
        <motion.div
          variants={sidebar}
          className={`background w-full h-full absolute top-0 right-0 z-0 bg-nav-bg`}
        />

        <Nav items={mainNav} onClose={closeNav} handles={socialHandles} />
      </motion.nav>
    </>
  );
};
