import React from 'react';
import { motion } from 'framer-motion';

// Animation
const top = {
  closed: {
    rotate: 0,
    translateY: 0,
  },
  open: {
    rotate: 45,
    translateY: 2,
  },
};

const bottom = {
  closed: {
    rotate: 0,
    translateY: 0,
  },
  open: {
    rotate: -45,
    translateY: -2,
  },
};

export const MenuToggle = ({ isOpen, reverseHeader, width = 15, height = 10, ...props }) => {
  const unitHeight = 4;
  const unitWidth = (unitHeight * width) / height;
  return (
    <motion.div
      {...props}
      className="w-12 h-12 flex items-center justify-center text-nav-text cursor-pointer fixed right-headerGutter top-9 bg-nav-bg rounded-full z-2500 lg:top-12 transition-all duration-300"
    >
      <svg
        viewBox={`0 0 ${unitWidth} ${unitHeight}`}
        overflow="visible"
        preserveAspectRatio="none"
        width={width}
        height={height}
        strokeWidth="1.8"
        className="stroke-current relative"
      >
        <motion.line
          x1="0"
          x2={unitWidth}
          y1="0"
          y2="0"
          variants={top}
          vectorEffect="non-scaling-stroke"
        />
        <motion.line
          x1="0"
          x2={unitWidth}
          y1="4"
          y2="4"
          variants={bottom}
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </motion.div>
  );
};
