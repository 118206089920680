import React from 'react';
import AnchorScroll from 'react-anchor-link-smooth-scroll';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Link } from 'gatsby';

import ArrowRight from '../../svg/arrow-right.svg';
import ChevronRight from '../../svg/chevron-right.svg';
import ChevronLeft from '../../svg/chevron-left.svg';

// Hooks & Querys
import { useSanityPath } from '../../hooks';
import { useAppContext } from '../../state';

export const PageLink = ({ page, linkText, ...rest }) => {
  const path = useSanityPath(page);
  return (
    <Link to={path} {...rest}>
      {linkText}
    </Link>
  );
};
export const PortablePageLink = ({ page, linkText, ...rest }) => {
  const path = useSanityPath(page);
  const { setCursorHover } = useAppContext();

  return (
    <div className="flex justify-end">
      <Link
        to={path}
        {...rest}
        className="flex items-center"
        onMouseEnter={() => setCursorHover(true)}
        onMouseLeave={() => setCursorHover(false)}
        onClick={() => setCursorHover(false)}
      >
        {linkText}
        <ArrowRight className="w-6 ml-4" />
      </Link>
    </div>
  );
};

export const PathLink = ({ path, linkText, ...rest }) => {
  return (
    <Link to={`/${path}`} {...rest}>
      {linkText}
    </Link>
  );
};

export const PageAnchorLink = ({ anchor, page, linkText, ...rest }) => {
  const path = useSanityPath(page);
  return (
    <AnchorLink to={`/${path}#${anchor}`} {...rest}>
      {linkText}
    </AnchorLink>
  );
};

export const AnchorScrollLink = ({ anchor, linkText, ...rest }) => {
  return (
    <AnchorScroll to={`#${anchor}`} {...rest}>
      {linkText}
    </AnchorScroll>
  );
};

export const ExternalLink = ({ linkText, link, ...rest }) => {
  return (
    <a href={link} target="_blank" rel="noreferrer" {...rest}>
      {linkText}
    </a>
  );
};

export const ArrowLink = ({ direction = 'left', ...props }) => {
  return (
    <Link
      {...props}
      className="w-10 h-10 border-current flex items-center justify-center rounded-full border"
    >
      {direction === 'left' ? (
        <ChevronLeft className="fill-current w-full h-full p-2" />
      ) : (
        <ChevronRight className="fill-current w-full h-full p-2" />
      )}
    </Link>
  );
};
