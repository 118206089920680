import React from 'react';
import { AnimatePresence, motion, useMotionValue, useSpring } from 'framer-motion';

import { useLocation } from '@reach/router';
import { useGesture } from 'react-use-gesture';

import { useAppContext } from '../state/index';

import '../css/main.css';

import { Header } from './nav/header';
import SkewScroller from './animation/skewScroller';

const cursorVars = {
  default: (x) => ({
    width: x ? 60 : 20,
    height: x ? 60 : 20,
    opacity: x ? 0.5 : 1,
  }),
  reversed: (x) => ({
    width: x ? 60 : 20,
    height: x ? 60 : 20,
    opacity: x ? 0.5 : 1,
  }),
  case: (x) => ({
    width: 80,
    height: 80,
  }),
};

const textVars = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 0.5,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const Layout = ({ children }) => {
  const { pathname } = useLocation();

  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);
  const { cusorState, cursorHover } = useAppContext();

  const springConfig = { damping: 40, stiffness: 300 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  const offset = cursorHover || cusorState === 'case' ? 30 : 10;

  const bind = useGesture({
    onMove: ({ values }) => {
      cursorX.set(values[0] - offset);
      cursorY.set(values[1] - offset);
    },
  });

  return (
    <>
      <div className="app-wrapper lg:cursor-none" {...bind()}>
        <AnimatePresence>
          <motion.div
            style={{
              translateX: cursorXSpring,
              translateY: cursorYSpring,
            }}
            className={`fixed left-0 top-0 rounded-full z-7000 pointer-events-none cursor-none items-center justify-center hidden lg:flex ${
              cusorState === 'reversed' ? 'bg-nav-text' : 'bg-pull-color'
            }`}
            variants={cursorVars}
            initial="default"
            animate={cusorState}
            custom={cursorHover}
          >
            <AnimatePresence>
              {cusorState === 'case' && (
                <motion.span
                  className="text-button-text text-sm text-center font-bold leading-tight"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={textVars}
                >
                  View Case
                </motion.span>
              )}
            </AnimatePresence>
          </motion.div>
        </AnimatePresence>
        <Header />
        <SkewScroller>
          <AnimatePresence exitBeforeEnter>
            <motion.main
              className="w-full"
              id="page_scroll"
              key={pathname}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                ease: 'easeInOut',
                duration: 0.5,
              }}
            >
              {children}
            </motion.main>
          </AnimatePresence>
        </SkewScroller>
      </div>
    </>
  );
};

export default Layout;
